body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.box {
  border-bottom: 1px solid black;
  text-align: left;
  padding-bottom: 10px;
  padding: 15px;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}



.box {
  border-bottom: 1px solid black;
  text-align: left;
  padding-bottom: 10px;
  padding: 15px;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

